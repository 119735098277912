/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

// export const query02 = graphql`
//   fragment getImage on SanityImage {
//     _key
//     _type
//     asset {
//       _id
//     }
//     crop {
//       top
//       right
//       left
//       bottom
//       _key
//       _type
//     }
//     hotspot {
//       height
//       _type
//       _key
//       width
//       x
//       y
//     }
//   }
//   query RootIndexQuery {
//     backgroundImage: file(relativePath: { eq: "structure.png" }) {
//       id
//       publicURL
//     }
//     landingPageSection: sanityLandingPageOrder {
//       items {
//         title
//         image {
//           ...getImage
//         }
//         _rawContent(resolveReferences: { maxDepth: 2 })
//         id
//       }
//     }
//     siteSettings: sanitySiteSetting {
//       socialMedia {
//         platform
//         url
//       }
//     }
//     headerNav: sanityNavigation(_id: { eq: "headerNavigation" }) {
//       id
//       items {
//         title
//         redirect
//         slug {
//           current
//         }
//         id
//       }
//     }
//   }
// `

// function to query title, description and author of page
function SEO({ description, lang, meta, title }) {
  const { site, siteSettings } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
        siteSettings: sanitySiteSetting {
          favIcon {
            asset {
              url
            }
          }
          title
          description
        }
      }
      `
    )

    // use description or siteSettings.description or siteMetadata.description as metaDescription
    const metaDescription = description || siteSettings?.description || site.siteMetadata.description
    // const headerNavTitle = query02.headerNav?.items.title

    // if (headerNavTitle === "Clipper" || headerNavTitle === "clipper") {
    //   return (
    //     <head>
    //     <meta http-equiv="refresh" content="7; url='https://biglink.to/zenstudios'" />
    //     </head>
    //   } else {
        return (
          <Helmet
          htmlAttributes={{
            lang,
          }}
          title={title}
          titleTemplate={`%s | ${siteSettings?.title || site.siteMetadata.title}`}
          meta={[
            {
              name: `description`,
              content: metaDescription,
            },
            {
              property: `og:title`,
              content: title,
            },
            {
              property: `og:description`,
              content: metaDescription,
            },
            {
              property: `og:type`,
              content: `website`,
            },
            {
              name: `twitter:card`,
              content: `summary`,
            },
            {
              name: `twitter:creator`,
              /* if siteSettings and author exist, use author.name || siteMetadata.author */
              content: siteSettings?.author?.name || site.siteMetadata.author,
            },
            {
              name: `twitter:title`,
              content: title,
            },
            {
              name: `twitter:description`,
              content: metaDescription,
            },
          ].concat(meta)}
          link={[
            {
              rel: "icon",
              type: "image/png",
              href: siteSettings?.favIcon?.asset.url,
            },
          ]}
          />
        )
      // }
    }

SEO.defaultProps = {
  lang: `de`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
